body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ResponsiveFont.css */

/* Define the base font size (you can adjust this value to your preference) */
:root {
  font-size: 16px;
}

/* Add media queries to adjust the font size at different screen sizes */
@media screen and (max-width: 768px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  :root {
    font-size: 12.55px;
  }
}

@font-face {
  font-family: "Playfair Display";
  src: url("../public/fonts/playfair-display/static/PlayfairDisplay-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Germania One";
  src: url("../public/fonts/germania-one/GermaniaOne-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.playfair-display {
  font-family: "Playfair Display", serif;
}
.germania-one {
  font-family: "Germania One", serif;
}

/* for radio boxes */
/* Hide the default radio button */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1rem;
  height: 1rem;
  border: 2px solid grey;
  border-radius: 50%;
}

/* Checked style */
input[type="radio"]:checked {
  background-color: #475156;
  border-color: #475156;
  /* padding: 10px; */
  /* pa */
}

/* for checkboxes */
/* Hide the default checkbox */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1.2rem;
  height: 1.3rem;
  border: 2px solid grey;
  border-radius: 4px;
}

/* Checked style */
input[type="checkbox"]:checked {
  background-color: rgb(33, 28, 28);
  border-color: transparent;
}

/* Custom checkmark */
input[type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode checkmark */
  display: block;
  text-align: center;
  line-height: 1rem;
  font-size: 0.9rem;
  font-weight: 900;
  color: white;
}
